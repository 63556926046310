import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ErrorTitleResolver  {

  constructor(private titleService: Title) { }

  resolve(route: ActivatedRouteSnapshot): string {
    const title = `Error ${route.params['code']} – DRC INSIGHT`;
    this.titleService.setTitle(title);
    return title;
  }
}
