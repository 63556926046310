import { Component, OnInit } from '@angular/core';
import { AppContextService, ApiGatewayService, ActivityMonitorService } from '@drc-eca/eca-components-lib';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { map, switchMap, catchError, mapTo, tap } from 'rxjs/operators';
import { of, Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OktaService } from 'src/app/core/services/okta.service';
import * as OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private apiGatewayService: ApiGatewayService,
    private appContextService: AppContextService,
    private activityMonitorService: ActivityMonitorService,
    private oktaService: OktaService,
    private router: Router,
    private route: ActivatedRoute) {
    }

  ngOnInit() {
    const timeout: boolean = this.route.snapshot.queryParams['timeout'] === 'true';

    this.route.params.pipe(
      switchMap(params => this.getProductCode(params)),
      tap(productCode => this.appContextService.setSelectedProductCode(productCode)),
      map(productCode => this.getReturnUrl(productCode)),
      switchMap(returnUrl => this.logout(returnUrl))
    ).subscribe(returnUrl => this.navigate(returnUrl, timeout));
  }

  private getProductCode(params: Params) {
    // if product code is specified in the URL, use it
    if (params.productCode) {
      return of(params.productCode);
    } else {
      // else, lookup previously selected product code
      return this.appContextService.getSelectedProductCode().pipe(
        map(productCode => productCode)
      );
    }
  }

  private getReturnUrl(productCode: string) {
    return productCode ? ['login', productCode] : ['login'];
  }

  private logout(returnUrl): Observable<any> {
    this.activityMonitorService.stopMonitoring();

    // Without a user token, the user is essentially already logged out. Exit here.
    if (!this.apiGatewayService.userToken) {
      return of(returnUrl);
    }

    return forkJoin([this.logoutEca(), this.logoutOkta()]).pipe(mapTo(returnUrl));
  }

  private logoutEca() {
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const url = `eca-security-service/${apiConfig}/v0/logout`;

    return this.appContextService.getToken().pipe(
      switchMap(token => this.apiGatewayService.post<any>(url, { token: token })),
      catchError((err) => {
        console.error(err);
        return of(undefined);
      })
    );
  }

  private async logoutOkta() {
    try {
      await this.oktaService.logout();
    } catch (err) {
      // ignore errors
      console.error(err);
    }
  }

  private navigate(url: string[], timeout: boolean) {
    this.appContextService.setToken(null);
    sessionStorage.clear();

    if (timeout) {
      return this.router.navigate(url, { queryParams: { timeout: true } });
    } else {
      return this.router.navigate(url);
    }
  }
}
