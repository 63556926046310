<div>

  <button mat-icon-button mat-dialog-close class="close-dialog">
    <mat-icon>close</mat-icon>
  </button>

  <h2 mat-dialog-title>
    {{data.title}}
  </h2>

  <!-- these two divs and classes are REQUIRED for proper sizing of the modal and scrolling behavior -->
  <div class="mat-dialog-title-content">
    <div class="scroll-container" [innerHtml]="data.message"></div>
  </div>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>
      Cancel
    </button>
    <button mat-button color="primary" [mat-dialog-close]="true">
      Try Again
    </button>
  </div>
</div>
