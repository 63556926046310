import { NgModule, Optional, SkipSelf } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EcaSnackbarAlertModule, EcaPillarBoxModule, EcaFormColumnModule, EcaBrowserDetectorModule, EcaDrcInsightLogoModule } from '@drc-eca/eca-components-lib';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserSecurityService } from '../user/services/user-security.service';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { LogoutComponent } from './logout/logout.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { SamlLoginComponent } from './saml-login/saml-login.component';
import { NewPasswordModule } from './new-password/new-password.module';
import { environment } from 'src/environments/environment';
import { OKTA_AUTH_CLIENT } from './services/okta.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ShortTokenRedirectComponent } from './short-token-redirect/short-token-redirect.component';

const oktaAuthClient = new OktaAuth(environment.okta.clientConfig);

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    EcaSnackbarAlertModule,
    RouterModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    EcaPillarBoxModule,
    EcaFormColumnModule,
    EcaDrcInsightLogoModule,
    MatDialogModule,
    MatIconModule,
    NewPasswordModule,
    EcaBrowserDetectorModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    PageNotFoundComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ActivateAccountComponent,
    LogoutComponent,
    SimpleDialogComponent,
    SamlLoginComponent,
    ShortTokenRedirectComponent
  ],
  exports: [
    PageNotFoundComponent
  ],
  providers: [
    { provide: OKTA_AUTH_CLIENT, useValue: oktaAuthClient }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
