import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppContextService } from '@drc-eca/eca-components-lib';
import { Router } from '@angular/router';
import { PortalService } from './core/portal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  constructor(
    private appContextService: AppContextService,
    private portalService: PortalService,
    private router: Router) {
  }

  ngOnInit() {
    const url = this.portalService.getPortalUrl(location);
    this.appContextService.setPortalUrl(url);
  }

  onActivate(): void {

    // dependent on eca-app-shell which has <div class="container"> with overflow-y: auto
    const containerElements = document.getElementsByClassName('container');

    if (containerElements && containerElements[0]) {
      containerElements[0].scrollTop = 0;
    }
  }

  logOut(): void {
    this.router.navigateByUrl('/login');
  }
}
