import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { AuthGuard } from './core/services/authorization-guard.service';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { LogoutComponent } from './core/logout/logout.component';
import { EcaAccessDeniedComponent, EcaErrorComponent } from '@drc-eca/eca-components-lib';
import { ActivateAccountComponent } from './core/activate-account/activate-account.component';
import { SamlLoginComponent } from './core/saml-login/saml-login.component';
import { GenericTitleResolver } from './shared/generic-title-resolver.service';
import { ErrorTitleResolver } from './shared/error-title-resolver.service';
import {
  ShortTokenRedirectComponent
} from './core/short-token-redirect/short-token-redirect.component';

export const appRoutes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'saml-login/:token',
    component: SamlLoginComponent,
    data: {
      genericTitle: 'Sign-In – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      genericTitle: 'Sign-Out – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'logout/:productCode',
    component: LogoutComponent,
    data: {
      genericTitle: 'Sign-Out – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      genericTitle: 'Reset Password – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'activate-account',
    component: ActivateAccountComponent,
    data: {
      genericTitle: 'Activate Account – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      genericTitle: 'Forgot Password – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'forgot-password/:productCode',
    component: ForgotPasswordComponent,
    data: {
      genericTitle: 'Forgot Password – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'user-email/:shortToken',
    component: ShortTokenRedirectComponent,
    data: {
      genericTitle: 'Forgot Password – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'products',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'docs',
    loadChildren: () => import('./docs/docs.module').then(m => m.DocsModule)
  },
  {
    path: 'access-denied',
    component: EcaAccessDeniedComponent,
    data: {
      genericTitle: 'Access Denied – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  },
  {
    path: 'error/:code',
    component: EcaErrorComponent,
    resolve: {
      title: ErrorTitleResolver
    }
  },
  {
    path: 'util',
    loadChildren: () => import('./util/util.module').then(m => m.UtilModule)
  },

  // Redirect old URL to new URL
  {
    path: 'privacy-policy',
    redirectTo: 'docs/privacy-policy',
    pathMatch: 'full'
  },

  // Redirect old URL to new URL
  {
    path: 'terms-of-use',
    redirectTo: 'docs/terms-of-use',
    pathMatch: 'full'
  },

  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      genericTitle: 'Page Not Found – DRC INSIGHT'
    },
    resolve: {
      title: GenericTitleResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes, { useHash: true })
  ],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
