<ng-container *ngIf="!(!form.show && form.status === 403); else show403Success">
  <app-new-password formTitle="Activate Account" saveButtonText="Activate" [userId]="userId" [token]="temporaryToken"
    (isLoading)="loading($event)" (formSubmit)="saveProfile($event)">
    <div class="ng-content-after">
      <br />
      <a *ngIf="showResendLink" (click)="resendEmail()" (keydown)="resendEmail()" style="cursor: pointer">Send a new activation email</a>
    </div>
  </app-new-password>
</ng-container>
<ng-template #show403Success>
  <eca-pillar-box>
    <eca-form-column>
      <span class="logo-group">
        <eca-drc-insight-logo></eca-drc-insight-logo>
      </span>
      <h1 class="eca-skip-link-focus-target" tabindex="-1">New Activation Submitted</h1>
      <p>
        Thank you for submitting your activation request.
        Please check your email for the next steps to complete your account activation.
      </p>
    </eca-form-column>
  </eca-pillar-box>
</ng-template>

<div class="spinner-overlay" fxLayout fxLayoutAlign="center center" *ngIf="isLoading">
  <mat-spinner diameter="48" color="primary"></mat-spinner>
</div>
