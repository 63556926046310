import { OnInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiGatewayService } from '@drc-eca/eca-components-lib';


@Component({
  selector: 'app-short-token-redirect',
  templateUrl: './short-token-redirect.component.html',
  styleUrls: ['./short-token-redirect.component.scss']
})
export class ShortTokenRedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiGatewayService: ApiGatewayService,
    public dialog: MatDialog
  ) {}

  getRoute(url: string) {
    const route = url.split('eca-portal-v2-ui/')[1];
    const n = route.lastIndexOf('/');
    return route.slice(0, n) + route.slice(n).replace('/', '');
  }

  async ngOnInit() {
    const { shortToken } = await this.route.params.pipe(take(1)).toPromise();
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const url = `eca-security-service/${apiConfig}/v0/email/shortUrl/${shortToken}`;

    try {
      const response: any = await this.apiGatewayService.get(url).toPromise();
      const targetRoute = this.getRoute(response.redirectUrl);

      await this.router.navigateByUrl(targetRoute);
    } catch (e) {
      const expiredText = 'This link has expired. Please click the reset password link again.';
      const errorPageText = e.status === 404 ? expiredText : e.statusText;

      await this.router.navigate([`/error/${e.status}`], { queryParams: { desc: errorPageText } });
    }
  }
}
