import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AppContextService, EcaSnackbarAlertComponent } from '@drc-eca/eca-components-lib';

import { Title } from '@angular/platform-browser';
import { UserManagementService } from 'src/app/user/services/user-management.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  snackBarRef: MatSnackBarRef<EcaSnackbarAlertComponent>;
  loginRoute: string = '/login';
  productCode: string;
  isSaving = false;

  constructor(
    private appContextService: AppContextService,
    private userManagementService: UserManagementService,
    private snackBar: MatSnackBar,
    fb: UntypedFormBuilder
  ) {

    const emailPattern = `^[a-zA-Z0-9!#$%&'*+\/=?^_‘{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+\\/=?^_‘{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$`;

    this.form = fb.group({
      userEmail: ['', Validators.pattern(emailPattern)]
    });
  }
  ngOnDestroy() {
    if (this.snackBarRef) {
      this.snackBar.dismiss();
    }
  }

  ngOnInit() {
    this.appContextService.getSelectedProductCode().subscribe(productCode => {
      if (productCode) {
        this.productCode = productCode;
        this.loginRoute = `/login/${productCode}`;
      }
    });
  }

  onSubmit(frm: UntypedFormGroup, formDirective: FormGroupDirective) {
    const message = 'If you entered a valid email address, we will send you an email with instructions on how to change your password.';
    if (frm.invalid) {
      return;
    }
    this.isSaving = true;
    this.userManagementService.forgotPassword(frm.controls.userEmail.value, this.productCode).subscribe(
      () => {
        this.isSaving = false;
        frm.reset();
        formDirective.resetForm();
        this.openGoodSnackBar(message);
      },
      (err) => {
        this.isSaving = false;
        this.openGoodSnackBar(message);
      }
    );
  }

  openGoodSnackBar(inMessage: string) {
    this.snackBarRef = this.snackBar.openFromComponent(EcaSnackbarAlertComponent, {
      panelClass: 'mat-snack-positive',
      verticalPosition: 'top',
      data: {
        icon: 'check_circle',
        message: inMessage
      }
    });
  }
}
