<div class="edit-container">
  <div class="form-container" fxLayout="row" fxLayoutAlign="center">

    <div fxFlex.gt-sm="480px" style="position: relative">
      <form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit(form, formDirective)" #formDirective="ngForm">
        <h1 class="eca-skip-link-focus-target" tabindex="-1">Need help resetting your password?</h1>
        <p>Enter your email address, and we'll send instructions on how to create a new password.</p>
        <br>
        <mat-form-field>
          <mat-label>Your Account Email</mat-label>
          <input matInput formControlName="userEmail" tabindex="0" required>
          <mat-error>Email is not valid</mat-error>
        </mat-form-field>

        <div class="form-action-container">
          <button mat-button color="primary" [disabled]="form.invalid || isSaving">
            Send instructions
          </button>
          <br>
          <div>
            <a [routerLink]="[loginRoute]">Back to Login Screen </a>
          </div>
        </div>
      </form>
      <div *ngIf="isSaving" class="spinner-overlay">
        <mat-spinner diameter="48"></mat-spinner>
      </div>
    </div>
  </div>
</div>
