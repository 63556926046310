import { Component, OnInit } from '@angular/core';
import { ApiGatewayService, AppContextService, EcaSecurityService, WindowService } from '@drc-eca/eca-components-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { PortalService } from '../portal.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-saml-login',
  templateUrl: './saml-login.component.html',
  styleUrls: ['./saml-login.component.scss']
})
export class SamlLoginComponent implements OnInit {

  constructor(
    private appContextService: AppContextService,
    private apiGatewayService: ApiGatewayService,
    private portalService: PortalService,
    private securityService: EcaSecurityService,
    private windowService: WindowService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit() {
    const {token} = await this.route.params.pipe(take(1)).toPromise();

    try {
      await this.securityService.authenticateToken(token).toPromise();
      this.appContextService.setToken(token);
      this.appContextService.setPortalUrl(this.portalService.getPortalUrl(location));
      this.apiGatewayService.clearCache();

      // restore product code set by product-login.component.ts
      const productCode = sessionStorage.getItem('saml-login-product');
      this.appContextService.setSelectedProductCode(productCode);
      sessionStorage.removeItem('saml-login-product');

      // navigate to redirect URL when one was provided
      const redirectUrl = sessionStorage.getItem('saml-login-redirect-url');
      if (redirectUrl) {
        const redirectUrlWithParams = new URL(redirectUrl);
        redirectUrlWithParams.searchParams.set('c', productCode);
        redirectUrlWithParams.searchParams.set('t', token);
        sessionStorage.removeItem('saml-login-redirect-url');
        this.windowService.navigateTo(redirectUrlWithParams.href);
        return;
      }

      this.appContextService.getSelectedProductCode().subscribe(productCode => {
        if (productCode) {
          this.router.navigate(['/products', productCode, 'welcome']);
        } else {
          this.router.navigate(['/products']);
        }
      });

    } catch (err) {
      console.error(err);
      this.router.navigate(['/logout']);
    }
  }

}
